import { useEffect, useState } from "react";
import { PrimaryButton } from "@gf-easy-8/grmnext-foundation";
import styled from "styled-components";

import { isMobile, isAndroid, isIOS } from "react-device-detect";

import { logVisit } from "./actions";
import { IPlatform } from "./type";
import { PLATFORMS } from "config/constants";

const FullScreenCenteredContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
FullScreenCenteredContainer.displayName = "FullScreenCenteredContainer";

const AppLogo = styled.img`
  width: 100px;
  margin-bottom: 20px;
`;
AppLogo.displayName = "AppLogo";

const AppLabel = styled.b`
  margin-bottom: 80px;
`;
AppLabel.displayName = "AppLabel";

export const DeviceView = {
  COMMUNICATION_DEVICE: "Communication Device",
  EQUIPMENT: "Equipment",
  SYSTEM: "System",
  DEFAULT_PARENT_DEVICE_SELECT: "Select Parent Device",
  DEFAULT_INSTALLATION_SELECT: "Select Installation",
  FORM_TAB: {
    NEW_COMM_DEV_TAB: 0,
    NEW_SUB_DEV_TAB: 1,
    NEW_SYSTEM_TAB: 2,
  },
};

const Root = () => {
  const [platform, setPlatform] = useState<IPlatform>();
  useEffect(() => {
    if (isAndroid) {
      setPlatform(PLATFORMS.ANDROID);
    } else if (isIOS) {
      setPlatform(PLATFORMS.IOS);
    } else {
      setPlatform(PLATFORMS.OTHERS);
    }
    const data = {
      name: platform ? platform.name : "",
    };
  }, []);

  const goToAppLink = () => {
    if (platform?.url) {
      window.location.href = platform?.url;
    }
  };

  useEffect(() => {
    const data = {
      platform: platform ? platform.name : "",
    };
    if (platform) {
      // logVisit(data).then((resp) => {
      //   if (resp.status === 200) {
      //     goToAppLink();
      //   }
      // });
      goToAppLink();
    }
  }, [platform]);

  return (
    <FullScreenCenteredContainer>
      <AppLogo src="/img/grpapplogo.png" />
      <AppLabel>Grundfos GO</AppLabel>
      <PrimaryButton onClick={() => goToAppLink()}>Get The App</PrimaryButton>
    </FullScreenCenteredContainer>
  );
};

export default Root;
