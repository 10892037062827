"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var sortable_table_hook_1 = require("../sortable-table/sortable-table.hook");
exports.usePagination = function (headers, pagination, onChange) {
    var currentPage = pagination.currentPage, rowsPerPage = pagination.rowsPerPage;
    var _a = react_1.useState(currentPage), currentPageValue = _a[0], setCurrentPageValue = _a[1];
    var _b = react_1.useState(rowsPerPage), rowsPerPageValue = _b[0], setRowsPerPageValue = _b[1];
    var handleSortChange = function (direction, column) {
        setCurrentPageValue(1);
        onChange(1, rowsPerPageValue, { direction: direction, column: column });
    };
    var sorting = sortable_table_hook_1.useColumnSort(headers, handleSortChange);
    var handlePaginationChange = function (newPage, newRowsPerPage) {
        setCurrentPageValue(newPage);
        setRowsPerPageValue(newRowsPerPage);
        onChange(newPage, newRowsPerPage, {
            direction: sorting.sortDirection,
            column: sorting.currentSortColumn,
        });
    };
    return {
        sorting: sorting,
        pagination: {
            currentPage: currentPageValue,
            rowsPerPage: rowsPerPageValue,
            onChange: handlePaginationChange,
        },
    };
};
