"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
function SvgPumpInstallationOutline(props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" }, props),
        React.createElement("path", { d: "M29 13a4 4 0 00-3-3.86V4h-2v5.14a4 4 0 000 7.72V21h-7V4h-2v5H8V4H6v11H3v8h3v5h2v-5h3v-8H8v-4h7v17h2v-5h7v5h2V16.86A4 4 0 0029 13zM9 21H5v-4h4zm16-6a2 2 0 112-2 2 2 0 01-2 2z" })));
}
exports.default = SvgPumpInstallationOutline;
