"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
function SvgLogoWithText(props) {
    return (React.createElement("svg", __assign({ width: 32, height: 32, xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M14.424 18.632s.477.289 1.494 1.299c1.031 1.024 1.34 1.502 1.34 1.502L12.21 26.5H6.59c2.59-2.599 5.142-5.163 6.599-6.626l.245-.247.729-.732.112-.113.045-.045.104-.105zm8.49.237a32.957 32.957 0 012.617 3.598c.784 1.259 1.507 2.605 1.988 4.033h-3.16s-.369-1.045-1.481-2.791c-.964-1.516-1.854-2.458-2.054-2.662l-.042-.042 2.133-2.136zM27.519 5.5v5.627l-8.122 8.156s-.879-.922-1.331-1.367c-4.773-4.71-9.201-6.993-10.303-5.904-1.476 1.455 3.08 6.76 3.239 6.996l-1.383 1.38c-2.975-3.635-3.802-5.22-4.56-7.107-.483-1.205-1.078-3.562.153-4.798 1.092-1.094 3.237-.716 5.4.256 2.482 1.114 5.34 3.147 8.028 5.671l8.88-8.91z", fill: "#FFF", fillRule: "evenodd" })));
}
exports.default = SvgLogoWithText;
