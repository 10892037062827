"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
function SvgLogoWithoutSpace(props) {
    return (React.createElement("svg", __assign({ width: 178, height: 24.2, xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M140 11.5c3 0 5.6.3 6.6.4l-1.5 2.3c-1.3-.3-3.1-.4-4-.4-1.5 0-3.8 0-3.8 1.1s1.5 1.1 3.8 1.5c2.8.4 5.7 1.1 5.7 3.8 0 2.9-2.8 4-8.7 4-2.6 0-4-.1-5.2-.2h-.4c-.5-.1-1.1-.1-1.7-.2l1.7-2.7c2 .4 3.7.5 4.3.5 2 0 4-.2 4-1.4 0-1.1-1.5-1.1-3.7-1.5-.2 0-.3-.1-.5-.1l-.5-.1c-2.5-.4-4.8-1.1-4.8-3.6-.1-2.5 3.3-3.4 8.7-3.4zm-20.3.1c6.4 0 9.3 2.4 9.3 6.2 0 4.3-2.9 6.4-9.3 6.4-6.5 0-9.3-2.1-9.3-6.4-.1-3.9 2.8-6.2 9.3-6.2zm-76.4.3v6.7c0 1.1.2 1.9.7 2.4.4.4 1 .7 1.9.7.9 0 1.5-.2 1.9-.7.5-.5.7-1.3.7-2.4v-6.7h5v7c0 1.9-.4 2.7-1.2 3.5-1.3 1.2-3.7 1.8-6.5 1.8s-5.2-.5-6.4-1.8c-.8-.8-1.2-1.6-1.2-3.5v-7h5.1zm-33.9-.3c1.9 0 4.1 0 7.6.5l-1.6 2.6-.6-.1c-1.5-.3-2.6-.4-4.7-.4-3.2 0-4.7 1.3-4.7 3.7 0 1.6.4 3.7 4.4 3.7.7 0 2.1-.2 2.1-.2V18h5v5.3c-2.1.5-4.7.7-7.5.7-6.5.1-9.4-1.6-9.4-6 0-4.3 2.9-6.4 9.4-6.4zm52.7.3l6.9 7.2v-7.2h3.3v11.9h-5.1l-6.9-7.1v7.1H57V11.9h5.1zm-31.9 0c2.2 0 5.2.2 5.2 3.4 0 3.1-2.3 3.4-2.8 3.5l3.5 5h-5.5l-3.4-5.4c2.2 0 3-.6 3-2.2 0-1.2-.8-2-2.3-2h-2.7v9.5h-5V11.9h10zm54 0c5.9 0 8.7 1.2 8.7 5.9s-2.8 6-8.7 6h-8.1V11.9h8.1zm25.3 0l-1.6 2.6H101v2.9h7.2l-1.6 2.6H101v3.9h-5.1v-12h13.6zm53.7 3s.5.3 1.7 1.5 1.5 1.7 1.5 1.7l-5.7 5.7h-6.4l8-8 .3-.3.4-.4.2-.2zm9.6.3c1 1.2 2 2.5 3 4.1.9 1.4 1.7 3 2.3 4.6h-3.6s-.4-1.2-1.7-3.2c-1.2-2-2.4-3.1-2.4-3.1l2.4-2.4zm-53.1-1.3c-2.6 0-3.8 1.3-3.8 3.9 0 2.6 1.3 3.9 3.8 3.9 2.7 0 3.8-1.2 3.8-3.9 0-2.5-1.2-3.9-3.8-3.9zm-36 .4h-2.6v7h2.6c2.6 0 3.9-1.3 3.9-3.5 0-2.3-1.2-3.5-3.9-3.5zM178 0v6.4l-9.2 9.2s-1-1-1.5-1.6c-5.4-5.3-10.4-7.9-11.7-6.7-1.7 1.6 3.5 7.7 3.7 7.9l-1.6 1.6c-3.1-3.7-4.1-5.6-4.9-7.5l-.2-.3c0-.1-.1-.1-.1-.2-.5-1.4-1.2-4 .2-5.4 1.2-1.2 3.7-.8 6.1.3 2.8 1.3 6.1 3.6 9.1 6.4L178 0z", fill: "currentColor", fillRule: "evenodd" })));
}
exports.default = SvgLogoWithoutSpace;
