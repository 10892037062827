"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = require("styled-components");
exports.rotate = styled_components_1.keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n"], ["\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n"])));
exports.pulse = styled_components_1.keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0% {\n    transform: scale(0.95);\n    opacity: 0.7;\n  }\n\n  40% {\n    transform: scale(1);\n    opacity: 1;\n  }\n\n  70% {\n    transform: scale(1.2);\n    opacity: 0.25;\n  }\n  \n  100% {\n    opacity: 0;\n  }\n"], ["\n  0% {\n    transform: scale(0.95);\n    opacity: 0.7;\n  }\n\n  40% {\n    transform: scale(1);\n    opacity: 1;\n  }\n\n  70% {\n    transform: scale(1.2);\n    opacity: 0.25;\n  }\n  \n  100% {\n    opacity: 0;\n  }\n"])));
var templateObject_1, templateObject_2;
