"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_day_picker_1 = require("react-day-picker");
var react_1 = require("react");
function useDateRangePickerInteractions(props) {
    var inFocus = props.inFocus, setInFocus = props.setInFocus, isOpen = props.isOpen, setIsOpen = props.setIsOpen, from = props.from, to = props.to, setLocalSelectedDays = props.setLocalSelectedDays, localSelectedDays = props.localSelectedDays, selectedDays = props.selectedDays, onApply = props.onApply;
    var onChange = function (range) {
        setLocalSelectedDays(range);
    };
    var handleDayClick = function (day) {
        if (inFocus === 'from') {
            onChange({ from: day, to: react_day_picker_1.DateUtils.isDayAfter(day, to) ? day : to });
            setInFocus('to');
        }
        else {
            onChange({ from: react_day_picker_1.DateUtils.isDayBefore(day, from) ? day : from, to: day });
            setInFocus('from');
        }
    };
    var cancel = react_1.useCallback(function () {
        setLocalSelectedDays(selectedDays);
        setIsOpen(false);
    }, [selectedDays, setIsOpen, setLocalSelectedDays]);
    var toggle = function () {
        if (isOpen) {
            cancel();
            return;
        }
        setIsOpen(true);
    };
    var apply = function () {
        onApply(localSelectedDays);
        setIsOpen(false);
        setInFocus('from');
    };
    return [handleDayClick, toggle, onChange, apply, cancel];
}
exports.default = useDateRangePickerInteractions;
