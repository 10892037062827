"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
function SvgMoveOutline(props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" }, props),
        React.createElement("path", { d: "M25.7 11.29l-1.42 1.42L26.57 15h-9.58V5.41l2.29 2.3 1.42-1.42-4.71-4.7-4.71 4.7 1.42 1.42 2.29-2.3V15H5.4l2.3-2.29-1.42-1.42L1.57 16l4.71 4.71 1.42-1.42L5.4 17h9.59v9.59l-2.29-2.3-1.42 1.42 4.71 4.7 4.71-4.7-1.42-1.42-2.29 2.3V17h9.58l-2.29 2.29 1.42 1.42L30.4 16l-4.7-4.71z" })));
}
exports.default = SvgMoveOutline;
