"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function useHighestSetting(settings) {
    var highestValue = 0;
    settings.forEach(function (setting) {
        if (setting.value > highestValue)
            highestValue = setting.value;
    });
    return highestValue;
}
exports.default = useHighestSetting;
