"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var polished_1 = require("polished");
exports.lightBlue = '#e6f0f7';
exports.blueHover = '#1a77bb';
exports.blue = '#0068b4';
exports.blue_10 = '#34648F';
exports.blueFocus = '#045794';
exports.darkBlue = '#11497B';
exports.skyBlue = '#4dbbeb';
// Graph colors
exports.graphColorOne = '#3386C3';
exports.graphColorTwo = '#E6DCBD';
exports.graphColorThree = '#7ABFA8';
exports.graphColorFour = '#D6D357';
exports.graphColorFive = '#33A3DC';
exports.graphColorSix = '#935029';
exports.red = '#e33353';
exports.darkerRed = '#F7064F';
exports.redHover = '#e01a3e';
exports.redFocus = '#b80425';
exports.offBlack = '#383a3c';
exports.offBlack_10 = '#2F3133';
exports.darkOffBlack = '#222527';
exports.lightBlack = '#121516';
exports.black = '#000000';
exports.seeThroughBlack = polished_1.transparentize(0.6, exports.black);
exports.lightGray = '#ebebeb';
exports.white = '#FFFFFF';
exports.fadedWhite = polished_1.transparentize(0.4, exports.white);
exports.lightFadedWhite = polished_1.transparentize(0.62, exports.white);
exports.lightWhite = polished_1.transparentize(0.1, exports.white);
exports.greyFaded = '#838586';
exports.darkGrey = '#2A2C2E';
exports.green = '#6EB456';
exports.yellow = '#F6AA33';
