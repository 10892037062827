"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var locale_context_1 = require("./locale.context");
var Locale = function (_a) {
    var children = _a.children, _b = _a.userLocale, userLocale = _b === void 0 ? 'da-DK' : _b;
    // Add dynamic loading of current locale later.
    var currentLocale = react_1.useState(userLocale)[0];
    var context = react_1.useMemo(function () {
        return {
            formatNumber: function (value) {
                return value.toLocaleString(currentLocale);
            },
            formatWithDigits: function (value, significantDigits) {
                if (significantDigits === void 0) { significantDigits = 0; }
                return new Intl.NumberFormat(currentLocale, { maximumFractionDigits: significantDigits }).format(value);
            },
        };
    }, [currentLocale]);
    return react_1.default.createElement(locale_context_1.LocaleProvider, { value: context }, children);
};
exports.default = Locale;
