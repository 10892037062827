"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = require("styled-components");
// This hook exists only to be able to use the useTheme hook from styled-components without losing type safety.
// The documentation from styled-componentns describes using declaration merging for extending the DefaultTheme.
// This is however based on a Typescript interface, which does not easily merge with a type declaration.
// https://styled-components.com/docs/api#typescript
function useTheme() {
    return styled_components_1.useTheme();
}
exports.default = useTheme;
