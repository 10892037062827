"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var emailRegex = /\S+@\S+\.\S+/;
var telephoneRegex = /^[0-9\\-]+$/;
var postcodeRegex = /[^@#$%^&*(){}]/;
function isEmailValid(email) {
    return emailRegex.test(email);
}
exports.isEmailValid = isEmailValid;
function isTelephoneValid(telephone) {
    return telephoneRegex.test(telephone);
}
exports.isTelephoneValid = isTelephoneValid;
function isPostcodeValid(postcode) {
    return postcodeRegex.test(postcode);
}
exports.isPostcodeValid = isPostcodeValid;
