"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var sortable_table_types_1 = require("./sortable-table.types");
exports.useColumnSort = function (headers, onChange) {
    var _a = react_1.useState(sortable_table_types_1.SortDirection.ascending), sortDirection = _a[0], setSortDirection = _a[1];
    var _b = react_1.useState(headers.find(function (header) { return header.sortBy || header.sort; })), currentSortColumn = _b[0], setCurrentSortColumn = _b[1];
    var onChangeRef = react_1.useRef(onChange);
    var isFirstRender = react_1.useRef(true);
    react_1.useEffect(function () {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (onChangeRef.current) {
            onChangeRef.current(sortDirection, currentSortColumn);
        }
    }, [currentSortColumn, sortDirection]);
    var onSortBy = function (header) {
        if (!header.sort && !header.sortBy)
            return;
        if (header === currentSortColumn) {
            setSortDirection(sortDirection === sortable_table_types_1.SortDirection.ascending ? sortable_table_types_1.SortDirection.descending : sortable_table_types_1.SortDirection.ascending);
        }
        else {
            setCurrentSortColumn(header);
        }
    };
    return { sortDirection: sortDirection, currentSortColumn: currentSortColumn, setCurrentSortColumn: onSortBy };
};
