export const PLATFORMS = {
  ANDROID: {
    name: "android",
        url: "https://play.google.com/store/apps/details?id=com.grundfos.go2",
  },
  IOS: {
    name: "ios",
      url: "https://apps.apple.com/us/app/grundfos-go-new-pump-tool/id1514389067",
  },
  OTHERS: {
    name: "others",
      url: "https://www.grundfos.com/dk/support",
  },
};
