import React from "react";
import { RootProvider } from "./provider/RootProvider";
import { renderRoutes } from "react-router-config";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components/macro";

import { DarkTheme } from "@gf-easy-8/grmnext-foundation";

import route from "./routes";

import "./App.css";

function App() {
  return (
    <RootProvider>
      <ThemeProvider theme={DarkTheme}>
        <BrowserRouter>{renderRoutes(route)}</BrowserRouter>
      </ThemeProvider>
    </RootProvider>
  );
}

export default App;
