"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useOutsideHover(mouseHover, setIsOpen) {
    var clickHandler = function () {
        if (!mouseHover) {
            setIsOpen(false);
        }
    };
    react_1.useEffect(function () {
        document.addEventListener('click', clickHandler);
        return function () { return document.removeEventListener('click', clickHandler); };
    });
}
exports.default = useOutsideHover;
