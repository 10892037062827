"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = __importStar(require("styled-components"));
exports.overlayEnter = styled_components_1.keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% { opacity: 0; }\n  100% { opacity: 1; }\n"], ["\n  0% { opacity: 0; }\n  100% { opacity: 1; }\n"])));
exports.overlayLeave = styled_components_1.keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0% { opacity: 1; }\n  100% { opacity: 0; }\n"], ["\n  0% { opacity: 1; }\n  100% { opacity: 0; }\n"])));
exports.enter = styled_components_1.keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n   0% { opacity: 0; top: calc(50% - 30px); }\n   100% { opacity: 1; top: 50%; }  \n"], ["\n   0% { opacity: 0; top: calc(50% - 30px); }\n   100% { opacity: 1; top: 50%; }  \n"])));
exports.leave = styled_components_1.keyframes(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  0% { opacity: 1; top: 50%; }\n  100% { opacity: 0; top: calc(50% - 30px); }     \n"], ["\n  0% { opacity: 1; top: 50%; }\n  100% { opacity: 0; top: calc(50% - 30px); }     \n"])));
exports.ModalDialog = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: fixed;\n  z-index: 100;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  display: inline-block;\n  padding: 22px;\n  width: ", ";\n  background-color: ", ";\n  color: ", ";\n\n  animation-name: ", ";\n  animation-duration: 0.6s;\n  animation-fill-mode: forwards;\n  box-sizing: border-box;\n"], ["\n  position: fixed;\n  z-index: 100;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  display: inline-block;\n  padding: 22px;\n  width: ", ";\n  background-color: ",
    ";\n  color: ",
    ";\n\n  animation-name: ", ";\n  animation-duration: 0.6s;\n  animation-fill-mode: forwards;\n  box-sizing: border-box;\n"])), function (_a) {
    var small = _a.small, wide = _a.wide;
    return (small ? '345px' : wide ? '1120px' : '805px');
}, function (_a) {
    var modal = _a.theme.colours.modal;
    return modal.backgroundColor;
}, function (_a) {
    var modal = _a.theme.colours.modal;
    return modal.color;
}, function (_a) {
    var show = _a.show;
    return (show ? exports.enter : exports.leave);
});
exports.ModalDialog.displayName = 'ModalDialog';
exports.ModalTitle = styled_components_1.default.h1(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: normal;\n  margin-top: 0;\n  color: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: normal;\n  margin-top: 0;\n  color: ",
    ";\n"])), function (_a) {
    var small = _a.small;
    return (small ? '14px' : '32px');
}, function (_a) {
    var small = _a.small, modal = _a.theme.colours.modal;
    return (small ? modal.headlineFadedColor : modal.headlineColor);
});
exports.ModalTitle.displayName = 'ModalTitle';
exports.ModalClose = styled_components_1.default.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: absolute;\n  right: 22px;\n  top: 22px;\n  cursor: pointer;\n\n  svg {\n    fill: ", ";\n    height: 32px;\n    width: 32px;\n  }\n"], ["\n  position: absolute;\n  right: 22px;\n  top: 22px;\n  cursor: pointer;\n\n  svg {\n    fill: ",
    ";\n    height: 32px;\n    width: 32px;\n  }\n"])), function (_a) {
    var modal = _a.theme.colours.modal;
    return modal.color;
});
exports.ModalClose.displayName = 'ModalClose';
exports.ModalFooter = styled_components_1.default.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  text-align: right;\n\n  button {\n    margin-right: 16px;\n    &:last-of-type {\n      margin-right: 0;\n    }\n  }\n"], ["\n  text-align: right;\n\n  button {\n    margin-right: 16px;\n    &:last-of-type {\n      margin-right: 0;\n    }\n  }\n"])));
exports.ModalFooter.displayName = 'ModalFooter';
exports.Overlay = styled_components_1.default.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: fixed;\n  z-index: 99;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  background-color: ", ";\n  animation-name: ", ";\n  animation-duration: 0.6s;\n  animation-fill-mode: forwards;\n"], ["\n  position: fixed;\n  z-index: 99;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  background-color: ",
    ";\n  animation-name: ", ";\n  animation-duration: 0.6s;\n  animation-fill-mode: forwards;\n"])), function (_a) {
    var modal = _a.theme.colours.modal;
    return modal.overlayBackgroundColor;
}, function (_a) {
    var show = _a.show;
    return (show ? exports.overlayEnter : exports.overlayLeave);
});
exports.Overlay.displayName = 'Overlay';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
