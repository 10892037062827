"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function calculatePosition(availableScreenHeight, highestSetting, value) {
    return Math.floor((availableScreenHeight / highestSetting) * value);
}
var calculateBottomPosition = function (calculationSettings, setting, previousSetting) {
    // We subtract a margin at the top because we don't want
    // the highest setting to clash with the outline of the sketch.
    var screenHeight = calculationSettings.screenHeight, highestSetting = calculationSettings.highestSetting;
    var availableScreenHeight = screenHeight - 20;
    var collisionDistance = 16;
    var offset = 0;
    var settingPosition = calculatePosition(availableScreenHeight, highestSetting, setting.value);
    //previousSetting is undefined => must be highest placed setting with no offset.
    if (previousSetting === undefined)
        return { position: settingPosition, offset: 0 };
    //if rows are within collision distance we add offset to current row to slide it further down until collision is avoided
    var distance = previousSetting.position - settingPosition;
    // this setting is conflicting with pre-previous settings, thus move it down below the previous setting.
    if (distance < 0)
        return { position: previousSetting.position - collisionDistance, offset: collisionDistance + Math.abs(distance) };
    // there is a collision with the previous setting , thus the offset is calculated and substracted from the position.
    if (distance < collisionDistance) {
        offset = collisionDistance - distance;
        settingPosition = settingPosition - offset;
    }
    return { position: settingPosition, offset: offset };
};
exports.default = calculateBottomPosition;
