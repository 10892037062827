"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var date_formatter_1 = require("../../dates/date-formatter");
exports.secondsToHours = function (value) { return (value && value / 3600) || null; };
exports.hoursToDuration = function (value) {
    return exports.secondsToDuration(value && value * 3600);
};
exports.secondsToDuration = function (value) {
    if (!value)
        return '00:00';
    return date_formatter_1.formatDuration(value);
};
exports.digitsFormatter = function (formatWithDigits, significantDigits) {
    return function (value) {
        if (value === undefined)
            return null;
        return formatWithDigits(value, significantDigits);
    };
};
exports.wattToKiloWatt = function (value) {
    if (!value)
        return null;
    return value / 1000;
};
exports.formatWattToKiloWatt = function (formatWithDigits) {
    return function (value) {
        if (value === undefined)
            return null;
        return formatWithDigits(value / 1000, 3);
    };
};
