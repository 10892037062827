"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// In kilometers
exports.clusterRadius = {
    1: 300,
    2: 200,
    3: 150,
    4: 100,
    5: 90,
    6: 80,
    7: 64,
    8: 32,
    9: 16,
    10: 8,
    11: 4,
    12: 2,
    13: 1,
    14: 0.5,
};
