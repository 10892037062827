"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var date_formatter_1 = require("./date-formatter");
// Date helper functions are the only methods
// allowed to import directly from any 3rd party date utility library.
// eslint-disable-next-line no-restricted-imports
var date_fns_1 = require("date-fns");
exports.minuteSeries = function (numberOfDates) {
    var dates = [];
    var firstDate = new Date();
    for (var i = 0; i < numberOfDates; i++) {
        dates.push(date_fns_1.set(date_fns_1.addMinutes(firstDate, i), { seconds: 0 }));
    }
    return dates;
};
exports.hourSeries = function (numberOfDates) {
    var dates = [];
    var firstDate = new Date();
    for (var i = 0; i < numberOfDates; i++) {
        dates.push(date_fns_1.set(date_fns_1.addHours(firstDate, i), { minutes: 0, seconds: 0 }));
    }
    return dates;
};
exports.sortByDate = function (firstDate, secondDate) {
    var _firstDate = date_formatter_1.parseDateTimeString(firstDate);
    var _secondDate = date_formatter_1.parseDateTimeString(secondDate);
    if (date_fns_1.isBefore(_firstDate, _secondDate))
        return -1;
    if (date_fns_1.isBefore(_secondDate, _firstDate))
        return 1;
    return 0;
};
exports.subtractDays = function (date, daysToSubtract) {
    return date_fns_1.subDays(date, daysToSubtract);
};
exports.difference = function (latestDate, earliestDate, unit) {
    if (unit === void 0) { unit = 'days'; }
    switch (unit) {
        case 'minutes': {
            return date_fns_1.differenceInMinutes(latestDate, earliestDate);
        }
        case 'hours': {
            return date_fns_1.differenceInHours(latestDate, earliestDate);
        }
        case 'days': {
            return date_fns_1.differenceInDays(latestDate, earliestDate);
        }
        case 'months': {
            return date_fns_1.differenceInMonths(latestDate, earliestDate);
        }
        case 'years': {
            return date_fns_1.differenceInYears(latestDate, earliestDate);
        }
    }
};
exports.dateMembers = function (date) {
    return [date_fns_1.getDate(date), date_fns_1.format(date, 'MMMM'), date_fns_1.getYear(date), date_fns_1.format(date, 'HH:mm'), date_fns_1.format(date, 'MM')];
};
exports.lastMinutes = function (minutes) {
    if (minutes === void 0) { minutes = 1; }
    var origin = new Date();
    return { from: date_fns_1.set(date_fns_1.subMinutes(origin, minutes), { seconds: 0 }), to: date_fns_1.set(origin, { seconds: 0 }) };
};
exports.excludeStartingMinute = function (from, to) {
    return {
        from: date_fns_1.addMinutes(from, 1),
        to: to,
    };
};
exports.lastHours = function (hours) {
    if (hours === void 0) { hours = 6; }
    var origin = new Date();
    return { from: date_fns_1.subHours(origin, hours), to: origin };
};
var defaultDate = function (date) {
    return date_fns_1.set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
};
// Query range means the dates will be manipulated to match
// API expectations.
exports.queryRange = function (from, to) {
    var defaultFrom = exports.subtractDays(defaultDate(new Date()), 8);
    var defaultTo = date_fns_1.addDays(defaultDate(new Date()), 1);
    return {
        from: (from && defaultDate(from)) || defaultFrom,
        to: (to && date_fns_1.addDays(defaultDate(to), 1)) || defaultTo,
    };
};
exports.formattedQueryRange = function (_a) {
    var from = _a.from, to = _a.to;
    return {
        from: from.toISOString(),
        to: to.toISOString(),
    };
};
// Use the "difference" helper above instead. This one may be removed in the future.
exports.getDifferenceInHours = function (latestDate, earliestDate) {
    return date_fns_1.differenceInHours(latestDate, earliestDate);
};
