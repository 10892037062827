"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable max-lines */
var polished_1 = require("polished");
var colors_1 = require("./colors");
var darkTheme = {
    colours: {
        app: {
            background: colors_1.lightBlack,
            color: colors_1.white,
        },
        global: {
            white: colors_1.white,
            allGood: colors_1.green,
            alarm: colors_1.red,
            warning: colors_1.yellow,
            offline: colors_1.offBlack,
            lightWhite: colors_1.lightWhite,
            lightFadedWhite: colors_1.lightFadedWhite,
            offBlack_10: colors_1.offBlack_10,
        },
        notification: {
            color: colors_1.white,
        },
        loading: {
            color: colors_1.white,
        },
        error: {
            color: colors_1.white,
        },
        storybook: {
            background: colors_1.lightBlack,
            color: colors_1.white,
        },
        switch: {
            activeBackground: colors_1.blue,
            handleColor: colors_1.lightBlue,
            inactiveBackground: colors_1.offBlack,
            inactiveHandle: colors_1.lightGray,
            titleColor: colors_1.lightWhite,
        },
        link: {
            color: colors_1.fadedWhite,
            hover: colors_1.white,
        },
        skeleton: {
            highlight: colors_1.fadedWhite,
            baseColor: colors_1.greyFaded,
        },
        button: {
            primary: {
                background: colors_1.blue,
                backgroundHover: colors_1.blueHover,
                backgroundFocus: colors_1.blueFocus,
                text: colors_1.white,
                disabled: colors_1.lightFadedWhite,
            },
            secondary: {
                color: colors_1.lightWhite,
            },
            alarm: {
                background: colors_1.red,
                backgroundHover: colors_1.redHover,
                backgroundFocus: colors_1.redFocus,
                text: colors_1.white,
            },
            logout: {
                color: colors_1.white,
            },
            text: {
                color: colors_1.skyBlue,
            },
        },
        commonRow: {
            color: colors_1.fadedWhite,
            disabled: colors_1.lightFadedWhite,
        },
        textInput: {
            title: {
                color: colors_1.white,
            },
            input: {
                borderColor: colors_1.offBlack_10,
                invalidBorderColor: colors_1.darkerRed,
                color: colors_1.white,
                placeholderColor: colors_1.fadedWhite,
                unitColor: colors_1.white,
                iconColor: colors_1.lightWhite,
                backgroundColor: colors_1.offBlack_10,
                searchBackgroundColor: colors_1.darkOffBlack,
                invalidTextColor: colors_1.darkerRed,
            },
        },
        textLink: {
            color: colors_1.skyBlue,
        },
        tile: {
            headerColor: colors_1.fadedWhite,
            backgroundColor: colors_1.darkOffBlack,
            dropdownBackgroundColor: colors_1.offBlack_10,
            color: colors_1.lightWhite,
            titleColor: colors_1.lightWhite,
            textColor: colors_1.lightWhite,
        },
        dropdown: {
            background: colors_1.offBlack,
            backgroundHover: colors_1.lightBlack,
            text: colors_1.lightWhite,
            disabledText: colors_1.fadedWhite,
            defaultOption: colors_1.fadedWhite,
            borderColor: colors_1.lightGray,
            embeddedBackground: 'transparent',
            embeddedTextColor: colors_1.fadedWhite,
            invalidBorderColor: colors_1.darkerRed,
        },
        table: {
            headerBackground: colors_1.offBlack,
            headerColor: colors_1.white,
            rowBackground: colors_1.darkOffBlack,
            rowBackgroundStriped: colors_1.offBlack_10,
            rowColor: colors_1.white,
            borderColor: colors_1.lightBlack,
            options: colors_1.white,
            optionsBackground: colors_1.offBlack_10,
            optionsColor: colors_1.lightWhite,
        },
        tableInfoBox: {
            header: colors_1.fadedWhite,
            color: colors_1.fadedWhite,
            valueColor: colors_1.lightWhite,
            border: polished_1.transparentize(0.9, colors_1.white),
        },
        appHeader: {
            background: colors_1.darkBlue,
            color: colors_1.white,
            fadedColor: colors_1.fadedWhite,
        },
        sidebar: {
            background: colors_1.darkBlue,
            navSelected: colors_1.blue,
            navHover: colors_1.blueHover,
            text: colors_1.lightWhite,
            buttonColor: colors_1.lightWhite,
            border: colors_1.blue_10,
        },
        tab: {
            textColor: colors_1.fadedWhite,
            borderColor: colors_1.blue,
            textColorHover: colors_1.white,
            backgroundColor: colors_1.lightBlack,
            alternateSlotColor: colors_1.fadedWhite,
            altBackgroundColor: colors_1.darkOffBlack,
            tabWrapperBorderColor: colors_1.offBlack_10,
        },
        statusBar: {
            backgroundColor: colors_1.darkOffBlack,
            textColor: colors_1.fadedWhite,
            errorBackground: colors_1.red,
            warningBackground: colors_1.yellow,
            healthyBackground: colors_1.green,
        },
        map: {
            pinBackground: colors_1.blue,
            pinFill: colors_1.white,
            zoomControlBackground: colors_1.offBlack_10,
            zoomControlColor: colors_1.white,
            zoomControlBorder: colors_1.offBlack_10,
            zoomControlInnerBorder: colors_1.fadedWhite,
            zoomControlHoverColor: colors_1.offBlack,
            zoomControlBoxShadow: polished_1.opacify(0.82, colors_1.black),
            cardBorder: colors_1.fadedWhite,
            cardBackground: colors_1.darkOffBlack,
            cardColor: colors_1.fadedWhite,
            pointOfInterest: colors_1.skyBlue,
            labelColor: colors_1.lightGray,
            water: colors_1.offBlack,
            terrain: colors_1.offBlack_10,
            structures: colors_1.offBlack_10,
            pointsOfInterest: colors_1.offBlack_10,
            roads: colors_1.darkOffBlack,
        },
        image: {
            trashCan: {
                backgroundColor: colors_1.offBlack_10,
                fillColor: colors_1.white,
            },
            uploader: {
                color: colors_1.white,
                backgroundColor: colors_1.offBlack_10,
            },
        },
        modal: {
            overlayBackgroundColor: colors_1.seeThroughBlack,
            backgroundColor: colors_1.darkOffBlack,
            color: colors_1.lightWhite,
            headlineColor: colors_1.lightWhite,
            headlineFadedColor: colors_1.fadedWhite,
        },
        list: {
            borderColor: colors_1.offBlack_10,
            color: colors_1.white,
        },
        checkbox: {
            backgroundColor: colors_1.blue,
            color: colors_1.lightWhite,
            fill: colors_1.lightWhite,
            disabledColor: colors_1.greyFaded,
        },
        switchButtons: {
            whiteBackground: colors_1.fadedWhite,
            blueBackground: colors_1.blue,
            textBlueBackground: colors_1.white,
            textWhiteBackground: colors_1.fadedWhite,
        },
        listViewRow: {
            green: colors_1.green,
            yellow: colors_1.yellow,
            red: colors_1.red,
            iconColor: colors_1.white,
        },
        charts: {
            border: colors_1.offBlack,
            label: colors_1.fadedWhite,
            labelHover: colors_1.lightWhite,
            colors: [colors_1.graphColorOne, colors_1.graphColorTwo, colors_1.graphColorThree, colors_1.graphColorFour, colors_1.graphColorFive, colors_1.graphColorSix],
            tooltipBackground: colors_1.offBlack_10,
            tooltipHeader: colors_1.lightWhite,
            tooltipValue: colors_1.lightWhite,
        },
        dateRangePicker: {
            backgroundColor: colors_1.offBlack,
            color: colors_1.fadedWhite,
            highlighted: colors_1.lightWhite,
            selected: colors_1.blue,
            selectedRange: colors_1.darkOffBlack,
        },
        loadingIcon: {
            full: colors_1.blueHover,
            empty: colors_1.lightGray,
        },
        icon: {
            fadedColor: colors_1.fadedWhite,
            normalColor: colors_1.white,
        },
        tooltip: {
            textColor: colors_1.white,
            backgroundColor: colors_1.blue,
            iconColor: colors_1.lightFadedWhite,
        },
        emptyState: {
            backgroundColor: colors_1.lightBlack,
            color: colors_1.white,
            fadedColor: colors_1.fadedWhite,
        },
        installationSketch: {
            alerts: colors_1.red,
            warnings: colors_1.yellow,
            allGood: colors_1.green,
            disabled: colors_1.lightFadedWhite,
            undetermined: colors_1.lightWhite,
            stroke: colors_1.lightWhite,
            water: polished_1.transparentize(0.7, colors_1.graphColorOne),
            waterSurface: colors_1.graphColorOne,
            color: colors_1.white,
        },
        search: {
            background: colors_1.darkOffBlack,
        },
        pagination: {
            color: colors_1.fadedWhite,
        },
        tag: {
            backgroundColor: colors_1.offBlack_10,
            textColor: colors_1.lightWhite,
        },
        buttonTile: {
            backgroundColor: colors_1.offBlack_10,
            iconColor: colors_1.white,
            titleColor: colors_1.skyBlue,
            descriptionColor: colors_1.lightFadedWhite,
        },
        snackbar: {
            textColor: colors_1.white,
            backgroundColor: colors_1.offBlack,
        },
    },
    breakpoints: {
        xs: '0px',
        sm: '600px',
        md: '960px',
        lg: '1280px',
        xl: '1440px',
    },
};
exports.default = darkTheme;
