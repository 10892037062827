"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
// @ts-nocheck
var react_dom_1 = require("react-dom");
var leaflet_1 = require("leaflet");
var react_leaflet_1 = require("react-leaflet");
// This component is an update of the DivIcon component
// from react-leaflet-div-icon package which uses a legacy
// version of the React context API, and is no longer maintained.
// The implementation from the npm package was also using deprecated
// life cycle methods (componentWillMount) which would have prevented
// us from updating React in the future.
// Don't add to this component if you can avoid it!
var DivIconV2 = /** @class */ (function (_super) {
    __extends(DivIconV2, _super);
    function DivIconV2(props) {
        var _this = _super.call(this, props) || this;
        _this.renderComponent = function () {
            var container = _this.leafletElement._icon;
            if (container) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                react_dom_1.render(_this.props.children, container);
            }
        };
        _this.leafletElement = _this.createLeafletElement(props);
        return _this;
    }
    // See https://github.com/PaulLeCam/react-leaflet/issues/275
    DivIconV2.prototype.createLeafletElement = function (newProps) {
        var position = newProps.position, props = __rest(newProps, ["position"]);
        return leaflet_1.marker(position, __assign({ riseOnHover: true, icon: new leaflet_1.DivIcon(props) }, props));
    };
    DivIconV2.prototype.updateLeafletElement = function (fromProps, toProps) {
        if (toProps.position !== fromProps.position) {
            this.leafletElement.setLatLng(toProps.position);
        }
        if (toProps.zIndexOffset !== fromProps.zIndexOffset) {
            this.leafletElement.setZIndexOffset(toProps.zIndexOffset);
        }
        if (toProps.opacity !== fromProps.opacity) {
            this.leafletElement.setOpacity(toProps.opacity);
        }
        if (toProps.draggable !== fromProps.draggable) {
            if (toProps.draggable) {
                this.leafletElement.dragging.enable();
            }
            else {
                this.leafletElement.dragging.disable();
            }
        }
    };
    DivIconV2.prototype.componentDidMount = function () {
        _super.prototype.componentDidMount.call(this);
        this.renderComponent();
    };
    DivIconV2.prototype.componentDidUpdate = function (fromProps) {
        this.renderComponent();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.updateLeafletElement(fromProps, this.props);
    };
    DivIconV2.prototype.render = function () {
        return null;
    };
    return DivIconV2;
}(react_leaflet_1.MapLayer));
exports.default = react_leaflet_1.withLeaflet(DivIconV2);
