import React, { ReactNode } from "react";
import { RouteConfig } from "react-router-config";
import App from "../App";
import Root from "../pages/Root";
import Denied from "../pages/Denied";
import { RootPath } from "./paths";

export default [
  {
    component: Root,
    exact: true,
    path: RootPath,
  }
  // { component: Denied, path: "*" },
] as RouteConfig[];
