"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
function SvgUsersOutline(props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" }, props),
        React.createElement("path", { d: "M23 12a3.86 3.86 0 002.84-1A4.69 4.69 0 0027 7.5C27 3.59 24.49 3 23 3a3.82 3.82 0 00-2.84 1A4.67 4.67 0 0019 7.5c0 3.91 2.5 4.5 4 4.5zm-1.44-6.56A1.88 1.88 0 0123 5c1.23 0 2 .42 2 2.5a2.94 2.94 0 01-.56 2.06A1.94 1.94 0 0123 10c-1.23 0-2-.42-2-2.5a2.92 2.92 0 01.56-2.06zM30.59 16.21A13.61 13.61 0 0023 14a14.18 14.18 0 00-7 1.85 19.56 19.56 0 012.62 1A12.37 12.37 0 0123 16a12.24 12.24 0 016 1.57V21h-7v2h9v-6.49zM10 18a16.78 16.78 0 00-8.54 2.18l-.46.29V27h18v-6.53l-.46-.29A16.78 16.78 0 0010 18zm7 7H3v-3.39A15.43 15.43 0 0110 20a15.69 15.69 0 017 1.61zM10 16a3.86 3.86 0 002.84-1A4.69 4.69 0 0014 11.5C14 7.59 11.49 7 10 7a3.82 3.82 0 00-2.85 1A4.67 4.67 0 006 11.5c0 3.91 2.5 4.5 4 4.5zM8.56 9.44A2.12 2.12 0 0110 9c1.23 0 2 .42 2 2.5a2.94 2.94 0 01-.56 2.06A1.81 1.81 0 0110 14c-1.23 0-2-.42-2-2.5a2.92 2.92 0 01.56-2.06z" })));
}
exports.default = SvgUsersOutline;
