"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = __importStar(require("styled-components"));
exports.Title = styled_components_1.default.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  color: ", ";\n  font-size: 14px;\n  margin-bottom: 4px;\n"], ["\n  display: block;\n  color: ", ";\n  font-size: 14px;\n  margin-bottom: 4px;\n"])), function (props) { return props.theme.colours.textInput.title.color; });
exports.Title.displayName = 'Title';
exports.InputContainer = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n"], ["\n  position: relative;\n  display: flex;\n"])));
exports.InputContainer.displayName = 'InputContainer';
exports.baseInputStyle = styled_components_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  opacity: ", ";\n  border: 1px solid\n    ", ";\n  background: ", ";\n  background: ", ";\n  width: 100%;\n  color: ", ";\n\n  ::placeholder {\n    color: ", ";\n  }\n"], ["\n  opacity: ", ";\n  border: 1px solid\n    ",
    ";\n  background: ", ";\n  background: ",
    ";\n  width: 100%;\n  color: ", ";\n\n  ::placeholder {\n    color: ", ";\n  }\n"])), function (_a) {
    var disabled = _a.disabled;
    return (disabled ? '.38' : '1');
}, function (_a) {
    var colours = _a.theme.colours, invalid = _a.invalid;
    return invalid ? colours.textInput.input.invalidBorderColor : colours.textInput.input.borderColor;
}, function (props) { return props.theme.colours.textInput.input.backgroundColor; }, function (_a) {
    var theme = _a.theme, rightIconPosition = _a.rightIconPosition;
    return rightIconPosition
        ? theme.colours.textInput.input.searchBackgroundColor
        : theme.colours.textInput.input.backgroundColor;
}, function (props) { return props.theme.colours.textInput.input.color; }, function (props) { return props.theme.colours.textInput.input.placeholderColor; });
exports.InputIcon = styled_components_1.default.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  top: 6px;\n  left: ", ";\n  right: ", ";\n\n  svg {\n    width: 20px;\n    fill: ", ";\n  }\n"], ["\n  position: absolute;\n  top: 6px;\n  left: ", ";\n  right: ", ";\n\n  svg {\n    width: 20px;\n    fill: ", ";\n  }\n"])), function (_a) {
    var isRightPositioned = _a.isRightPositioned;
    return (isRightPositioned ? 'unset' : '8px');
}, function (_a) {
    var isRightPositioned = _a.isRightPositioned;
    return (isRightPositioned ? '8px' : 'unset');
}, function (props) { return props.theme.colours.textInput.input.iconColor; });
exports.InputIcon.displayName = 'InputIcon';
exports.Input = styled_components_1.default.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  padding: 6px ", " 6px\n    ", ";\n  box-sizing: border-box;\n  font-size: 14px;\n  line-height: 18px;\n\n  &:focus {\n    outline: 0;\n  }\n"], ["\n  ", ";\n  padding: 6px ", " 6px\n    ", ";\n  box-sizing: border-box;\n  font-size: 14px;\n  line-height: 18px;\n\n  &:focus {\n    outline: 0;\n  }\n"])), exports.baseInputStyle, function (_a) {
    var unit = _a.unit, rightIconPosition = _a.rightIconPosition;
    return (unit ? '48px' : rightIconPosition ? '32px' : '8px');
}, function (_a) {
    var icon = _a.icon, rightIconPosition = _a.rightIconPosition;
    return (icon ? (rightIconPosition ? '8px' : '34px') : '8px');
});
exports.Input.displayName = 'Input';
exports.Unit = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  line-height: 18px;\n  font-size: 14px;\n  padding-top: 6px;\n  padding-right: 8px;\n  background-color: ", ";\n  color: ", ";\n"], ["\n  line-height: 18px;\n  font-size: 14px;\n  padding-top: 6px;\n  padding-right: 8px;\n  background-color: ", ";\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colours.textInput.input.backgroundColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.colours.textInput.input.unitColor;
});
exports.Unit.displayName = 'Unit';
exports.TextFieldWrapper = styled_components_1.default.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex-grow: 1;\n"], ["\n  flex-grow: 1;\n"])));
exports.TextFieldWrapper.displayName = 'TextFieldWrapper';
exports.TextError = styled_components_1.default.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 14px;\n  margin-top: 5px;\n"], ["\n  color: ", ";\n  font-size: 14px;\n  margin-top: 5px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colours.textInput.input.invalidTextColor;
});
exports.TextError.displayName = 'TextError';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
