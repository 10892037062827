"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    'GLOBAL:NO_DATA': {
        da: 'Ingen data',
        en: 'No data',
    },
    'GLOBAL:LOADING': {
        da: 'Indlæser',
        en: 'Loading',
    },
    'GLOBAL:ERROR': {
        da: 'Noget gik gelt.',
        en: 'Something went wrong.',
    },
    'GLOBAL:CANCEL': {
        da: 'Annuller',
        en: 'Cancel',
    },
    'GLOBAL:OK': {
        da: 'OK',
        en: 'OK',
    },
    'GLOBAL:SEARCH': {
        da: 'Søg efter noget',
        en: 'Search something',
    },
    'DATE_RANGE_PICKER:TIME_PERIOD': {
        da: 'Tidsperiode',
        en: 'Time period',
    },
    'DATE_RANGE_PICKER:YESTERDAY': {
        da: 'Sidste 24 timer',
        en: 'last 24 hours',
    },
    'DATE_RANGE_PICKER:LAST_WEEK': {
        da: 'Sidste 7 dage',
        en: 'Last 7 days',
    },
    'DATE_RANGE_PICKER:LAST_MONTH': {
        da: 'Sidste 30 dage',
        en: 'Last 30 days',
    },
    'DATE_RANGE_PICKER:LAST_THREE_MONTHS': {
        da: 'Sidste 90 dage',
        en: 'Last 90 days',
    },
    'DATE_RANGE_PICKER:CANCEL': {
        da: 'Annuller',
        en: 'Cancel',
    },
    'DATE_RANGE_PICKER:APPLY': {
        da: 'Anvend',
        en: 'Apply',
    },
    'DATES:MONTHS:JANUARY': {
        da: 'Januar',
        en: 'January',
    },
    'DATES:MONTHS:FEBRUARY': {
        da: 'Februar',
        en: 'February',
    },
    'DATES:MONTHS:MARCH': {
        da: 'Marts',
        en: 'March',
    },
    'DATES:MONTHS:APRIL': {
        da: 'April',
        en: 'April',
    },
    'DATES:MONTHS:MAY': {
        da: 'Maj',
        en: 'May',
    },
    'DATES:MONTHS:JUNE': {
        da: 'Juni',
        en: 'June',
    },
    'DATES:MONTHS:JULY': {
        da: 'Juli',
        en: 'July',
    },
    'DATES:MONTHS:AUGUST': {
        da: 'August',
        en: 'August',
    },
    'DATES:MONTHS:SEPTEMBER': {
        da: 'September',
        en: 'September',
    },
    'DATES:MONTHS:OCTOBER': {
        da: 'Oktober',
        en: 'October',
    },
    'DATES:MONTHS:NOVEMBER': {
        da: 'November',
        en: 'November',
    },
    'DATES:MONTHS:DECEMBER': {
        da: 'December',
        en: 'December',
    },
    'DATES:SHORT_WEEKDAYS:MONDAY': {
        da: 'M',
        en: 'M',
    },
    'DATES:SHORT_WEEKDAYS:TUESDAY': {
        da: 'T',
        en: 'T',
    },
    'DATES:SHORT_WEEKDAYS:WEDNESDAY': {
        da: 'O',
        en: 'W',
    },
    'DATES:SHORT_WEEKDAYS:THURSDAY': {
        da: 'T',
        en: 'T',
    },
    'DATES:SHORT_WEEKDAYS:FRIDAY': {
        da: 'F',
        en: 'F',
    },
    'DATES:SHORT_WEEKDAYS:SATURDAY': {
        da: 'L',
        en: 'S',
    },
    'DATES:SHORT_WEEKDAYS:SUNDAY': {
        da: 'S',
        en: 'S',
    },
    'DURATIONS:SHORT_HOURS': {
        da: 't',
        en: 'h',
    },
    'DURATIONS:SHORT_MINUTES': {
        da: 'm',
        en: 'm',
    },
    'SKETCH:START_LEVEL': {
        da: 'Næste pumpe start',
        en: 'Next pump start',
    },
    'SKETCH:STOP_LEVEL': {
        da: 'Næste pumpe stop',
        en: 'Next pump stop',
    },
    'PAGINATION:NO_OF_ROWS': {
        da: 'Antal rækker:',
        en: 'No. of rows:',
    },
    'PAGINATION:OF': {
        da: 'af',
        en: 'of',
    },
    'CHART:ONE_YEAR_BACK': {
        da: '-1 år',
        en: '-1 year',
    },
    'CHART:NOW': {
        da: 'Nu',
        en: 'Now',
    },
};
