"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SKETCH_CONSTANTS = Object.freeze({
    leftBreakPoint: 0.18,
    rightBreakPoint: 0.18,
    topOffset: 20,
    dashLength: 8,
    get middleDistance() {
        return 1 - this.leftBreakPoint - this.rightBreakPoint;
    },
});
exports.default = SKETCH_CONSTANTS;
