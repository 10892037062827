"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var date_helpers_1 = require("../../dates/date-helpers");
function isFirstOfDate(value, tickPositions) {
    var groupedByDate = tickPositions.reduce(function (result, current) {
        var date = date_helpers_1.dateMembers(new Date(current))[0];
        if (!result[date])
            result[date] = [];
        result[date].push(current);
        return result;
    }, {});
    var date = date_helpers_1.dateMembers(new Date(value))[0];
    return groupedByDate[date][0] === value;
}
exports.default = isFirstOfDate;
