"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_leaflet_bing_v2_1 = require("react-leaflet-bing-v2");
var styled_components_1 = require("styled-components");
var bingKey = 'AlYa_tFwamY78ffSuZHfNn7ZaVZpupLVrfT4ICEH1BPq7uiIiimnul40QiFY4uZx';
exports.constructMapStyles = function (styles) {
    return JSON.stringify(styles)
        .replace(/"*},"/g, '_')
        .replace(/":{"/g, '|')
        .replace(/":"*#*/g, ':')
        .replace(/","/g, ';')
        .replace(/{"|"*}}/g, '');
};
// https://github.com/microsoft/Bing-Maps-V8-TypeScript-Definitions/blob/master/types/MicrosoftMaps/CustomMapStyles.d.ts
var BingLayer = function () {
    var theme = react_1.useContext(styled_components_1.ThemeContext);
    var map = theme.colours.map;
    var defaultRoadStyles = {
        fillColor: map.roads,
        strokeColor: map.roads,
        labelColor: map.labelColor,
        labelOutlineColor: map.roads,
    };
    var defaultStructureStyles = {
        fc: map.structures,
        labelColor: map.labelColor,
        labelOutlineColor: map.structures,
    };
    var defaultTerrainStyles = {
        fc: map.terrain,
        labelColor: map.labelColor,
        labelOutlineColor: map.terrain,
    };
    var defaultBorderStyles = {
        borderVisible: false,
    };
    var mapStyles = exports.constructMapStyles({
        global: { landColor: map.terrain },
        mapElement: defaultTerrainStyles,
        wt: {
            fc: map.water,
            labelColor: map.labelColor,
            labelOutlineColor: map.water,
        },
        road: defaultRoadStyles,
        highway: defaultRoadStyles,
        majorRoad: defaultRoadStyles,
        arterialRoad: defaultRoadStyles,
        street: defaultRoadStyles,
        transportation: defaultRoadStyles,
        structure: defaultStructureStyles,
        education: defaultStructureStyles,
        medicalBuilding: defaultStructureStyles,
        adminDistrict: defaultBorderStyles,
        district: defaultBorderStyles,
    });
    return react_1.default.createElement(react_leaflet_bing_v2_1.BingLayer, { bingkey: bingKey, type: "RoadOnDemand", style: mapStyles });
};
BingLayer.displayName = 'BingLayer';
exports.default = BingLayer;
