"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
function SvgUserCircleOutline(props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" }, props),
        React.createElement("path", { d: "M16 8c-1.87 0-5 .78-5 6s3.13 6 5 6c5 0 5-4.53 5-6 0-5-2.72-6-5-6zm0 10c-1.29 0-3-.42-3-4s1.52-4 3-4 3 .48 3 4c0 3.35-1.1 4-3 4z" }),
        React.createElement("path", { d: "M16 2a14 14 0 1014 14A14 14 0 0016 2zM8.52 25.37a21.1 21.1 0 0115 0 12 12 0 01-15 0zm16.53-1.52a23.06 23.06 0 00-18.1 0 12 12 0 1118.1 0z" })));
}
exports.default = SvgUserCircleOutline;
