"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
function PointOfInterest(props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" }, props),
        React.createElement("path", { d: "M16,1.99999996 C9.45999998,1.99999996 5.99999997,5.60999997 5.99999997,12.45 C5.99999997,21.06 14.94,29.38 15.32,29.73 L16,30.35 L16.68,29.73 C17.06,29.38 26,21.06 26,12.45 C26,5.60999997 22.54,1.99999996 16,1.99999996 Z M20.5,12.5 C20.5,14.9852814 18.4852814,17 16,17 C13.5147186,17 11.5,14.9852814 11.5,12.5 C11.5,10.0147186 13.5147186,7.99999998 16,7.99999998 C18.4829999,8.00549944 20.4945005,10.0170001 20.5,12.5 Z", id: "Shape" })));
}
exports.default = PointOfInterest;
