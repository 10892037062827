"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InstallationStatus;
(function (InstallationStatus) {
    InstallationStatus["ALL_GOOD"] = "all-good";
    InstallationStatus["OFFLINE"] = "offline";
    InstallationStatus["ERROR"] = "error";
    InstallationStatus["WARNING"] = "warning";
})(InstallationStatus = exports.InstallationStatus || (exports.InstallationStatus = {}));
var MapInstallationType;
(function (MapInstallationType) {
    MapInstallationType[MapInstallationType["INSTALLATION"] = 0] = "INSTALLATION";
    MapInstallationType[MapInstallationType["CLUSTER"] = 1] = "CLUSTER";
})(MapInstallationType = exports.MapInstallationType || (exports.MapInstallationType = {}));
